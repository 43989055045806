<template>
  <Layout>
    <div class="float-right mb-4">
      <b-button class="bseen-btn" @click="showActionModal">Add</b-button>
    </div>
    <div class="clearfix"></div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="permissionData"
                :fields="fields"
                responsive="sm"
                :busy="isBusy"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <!-- <template #cell(actions)="row">
                  <button
                    class="action-btn"
                    @click="deletePermission(row.item)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  <button class="action-btn" @click="editPermission(row.item)">
                    <i class="fas fa-edit"></i>
                  </button>
                </template> -->
                
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <permissionModal
      :selectedPermission="selectedPermission"
      v-on:resetModal="selectedPermission = null"
      v-on:reloadData="getAllPermission"
    />
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import permissionModal from "@/components/users/permissions-modal";
import { permissions } from "@/config/api/permissions";

export default {
  components: { Layout, permissionModal },
  data() {
    return {
      permissionData: [],
      selectedPermission: null,
      userRoles: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "category", sortable: false },
        { key: "name", sortable: false },
        // { key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.permissionData.length;
    },
  },
  mounted() {},
  created() {
    this.getAllPermission();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllPermission() {
      this.isBusy = true;
      const api = permissions.get;
      this.generateAPI(api)
        .then((res) => {
          console.log("Prmissions", res.data);
          this.permissionData = res.data.permissions;
          this.totalRows = this.permissionData.length;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    showActionModal() {
      this.$bvModal.show("action-Permission");
    },

    editPermission(data) {
      console.log(data);
      this.selectedPermission = data;
      this.showActionModal();
    },
    deletePermission(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = permissions.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then((res) => {
                console.log(res.data);
                this.$bvToast.toast("Permission deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.getAllPermission();
              })
              .catch((err) => {
                this.$bvToast.toast("Permission is not deleted!", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
          // An error occurred
        });
    },
  },
};
</script>
