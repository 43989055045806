<template>
  <b-modal
    id="action-Permission"
    @hidden="$emit('resetModal')"
    :title="selectedPermission ? 'Update Permission' : 'Add Permission'"
    hide-footer
  >
      <FormulateForm
        name="permissionAction"
        v-model="values"
        :schema="schema"
        @submit="submitHandler"
      >
        <FormulateInput type="submit" class="float-right">
          <span v-if="performAction">
            <b-spinner class="vueformulate-loader"></b-spinner>
            <span v-if="selectedPermission"> Updating... </span>
            <span v-else> Adding... </span>
          </span>
          <span v-else>
            <span v-if="selectedPermission"> Update </span>
            <span v-else> Add </span>
            Permission</span
          >
        </FormulateInput>
      </FormulateForm>

  </b-modal>
</template>

<script>
import { permissions } from "@/config/api/permissions";
export default {
  props: ["selectedPermission"],
  data() {
    return {
      values: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
        {
          index: 1,
          type: "text",
          name: "category",
          label: "Category",
          validation: "required",
        },
    
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      if (this.selectedPermission) {
        this.updatePermission(data, this.selectedPermission._id);
      } else {
        this.addPermission(data);
      }
    },
    addPermission(data) {
      this.performAction = true;
      const api = permissions.create;
      api.data = data;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$formulate.reset("permissionAction");
          this.$bvToast.toast("Permission added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Permission is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updatePermission(data, id) {
      this.performAction = true;
      const api = permissions.update;
      api.data = data;
      api.id = id;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Permission updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Permission is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedPermission(val) {
      if (val) {
        this.values = { ...val };
      }
    },
  },
};
</script>

<style>
</style>